































































































































































































import {
  Component,
  Vue,
  Watch,
} from 'vue-property-decorator';
import MapView from '@/components/core/Map.vue';
import ProductOther from '@/components/core/ProductOther.vue'
import { User } from "@/store/user";
import { Auth } from "@/store/auth";
import { Core } from "@/store/core";
import { Map } from "@/store/map";
import { Product } from "@/store/product";
import { App } from "@/store/app";
import {
  City
} from "@/store/city";
import moment from 'moment'
@Component({
  components: { MapView, ProductOther, },
  computed: {},
  metaInfo() {
    return {
      title: "ตลาดควาย",
      meta: [{ name: 'description', content: 'ตลาดควาย' },
        { name: 'keywords', content: 'ตลาดควาย' },
 
        {property: 'og:title', content: "ตลาดควาย"},
        {property: 'og:site_name', content: "ตลาดควาย"},
        {property: 'og:type', content: 'article'},
        {property: 'og:url', content: document.URL},
        { property: 'og:image', content: 'https://www.buffalomarket.agri.up.ac.th/product/6.jpg' },
        {property: 'og:description', content: "ประกาศซื้อ-ขาย สินค้าเกี่ยวกับควาย"},
      ],
    };
  },

})

export default class PostSaller extends Vue {
  dialog: boolean = false
  url: any = this.$route.fullPath

  async created() {
    await this.loadProduct();

    await this.loadProducts()
    await this.loadFarm()

    // this.response = true
  }
  products: any = null
  product: any = null
  farm: any = {}
  response: boolean = false;
  user: any = null
  profile: any = null

  async loadFarm() {
    // this.user = await Auth.getUser()
    // this.profile = await User.getProfileFull();
    this.farm = this.product.farm // await Core.getHttp(`/api/default/farm/${this.product.farm.id}/`)
    this.profile = this.product.farm.user

  }

  async loadProduct() {
    let id = this.$route.query.product
    this.product = await Core.getHttp(`/api/default/products/${id}/`)
  }
  async loadProducts() {
    this.products = await Core.getHttp(`/api/default/products/?farm=${this.product.farm.id}`)

  }
  public async updateProduct(product: any) {
    let store = await Core.putHttp(`/api/default/products/${product.id}/`, product)
    if (store.id) {
      // alert("Save product success")
      await App.success("บันทึกข้อมูลสำเร็จ")
      await this.loadProduct()
    }
  }

  async openBrowser(position: any) {
    let me = `${position.coords.latitude},${position.coords.longitude}`
    let to = `${this.farm.latitude},${this.farm.longitude}`
    console.log(me, `https://www.google.com/maps/dir/?api=1&origin=${me}&destination=${to}`);
    window.open(`https://www.google.com/maps/dir/?api=1&origin=${me}&destination=${to}`, '_blank');
  }
  async openMap(location: any) {
    let origin = `19.0199381,99.9218023`
    if (navigator.geolocation) {
      await navigator.geolocation.getCurrentPosition((position: any) => {
        origin = `${position.coords.latitude},${position.coords.longitude}`
      });
    }
    window.open(`https://www.google.com/maps/dir/?api=1&origin=${origin}&destination=${location}`);

  }

  ximg(file: any) {
    return (file) ? file : 'https://images.pexels.com/photos/4052387/pexels-photo-4052387.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940'
  }

  dateOut(date: any) {
    let convert = moment(date).format('DD/MM/YYYY')
    return convert
  }

}
