


































import {
    Component,
    Vue,
    Watch,
} from 'vue-property-decorator';
import ProductDetail from "@/components/core/ProductDetail.vue"
import { User } from "@/store/user";
import { Auth } from "@/store/auth";
import { Core } from "@/store/core";
import { Map } from "@/store/map";
import { Product } from "@/store/product";
import {
    City
} from "@/store/city";
@Component({
    components: { ProductDetail },
    computed: {},
})

export default class PostSaller extends Vue {
    dialog: boolean = false
    async created() {
        await Core.switchLoad(true)
        await this.loadProduct();
        await Core.switchLoad(false)
        this.response = true

    }
    product: any = null
    response: boolean = false;

    async loadProduct() {
        let id = this.$route.query.product
        this.product = await Core.getHttp(`/api/default/products/${id}/`)
    }
}
